import React, {useEffect, useState} from 'react'
import {HomePreview} from "./components/home-preview";
import {Navbar} from "../../components/navbar";
import {HomeSlider} from "./components/home-slider";
import {HomeSliderMobile} from "./components/home-slider-mobile";
import {HomeAbout} from "./components/home-about";
import {HomeOffer} from "./components/home-offer";
import {HomePartners} from "./components/home-partners";
import {HomeUniverse} from "./components/home-universe";
import {HomeCategoriesList} from "./components/home-categories-list";
import {HomeCategoriesMobile} from "./components/home-categories-mobile";
import {HomeDetails} from "./components/home-details";
import {HomeDetailsCards} from "./components/home-details-cards";
import {HomeCreation} from "./components/home-creation";
import {HomeTeam} from "./components/home-team";
import {HomeBigSlider} from "./components/home-big-slider";
import {HomeSliderBigSizeMobile} from "./components/home-slider-bg-size-mobile";
import {HomeRoadmap} from "./components/home-roadmap";
import {HomeMint} from "./components/home-mint";
import {HomeFaq} from "./components/home-faq";
import {HomeFooter} from "./components/home-footer";
import {HomeCreationMobile} from "./components/home-creation-mobile";
import axios from "axios";

export function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const HomePage = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [monetPrice, setMonetPrice] = useState(1)

  useEffect(() => {
    function handleWindowResize() {
      if(getWindowSize().innerWidth === windowSize.innerWidth) return
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    getData( )

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const getData = async () => {
    const response = await axios.get('https://api.binance.com/api/v3/ticker/price?symbol=MATICUSDT'
    );

    if (response?.data?.price) {
      setMonetPrice(Number(response.data.price * 80))
    }


  }

  return (
    <div>
      <Navbar />
      <HomePreview windowSize={windowSize} />
      {
        windowSize.innerWidth > 720 ? (
          <HomeSlider />
        ) : (
          <HomeSliderMobile />
        )
      }
      <HomeAbout />
      <HomeOffer monetPrice={monetPrice.toFixed()} windowSize={windowSize} />
      <HomePartners />
      <HomeUniverse />
      {
        windowSize.innerWidth > 1100 ? (
          <HomeCategoriesList />

        ) : (
          <HomeCategoriesMobile />
        )
      }
      <HomeDetails windowSize={windowSize} />
      <HomeDetailsCards />
      {
        windowSize.innerWidth > 1150 ? (
          <HomeCreation monetPrice={monetPrice.toFixed()} windowSize={windowSize} />
        ) : (
          <HomeCreationMobile />
        )
      }
      <HomeTeam />
      {
        windowSize.innerWidth > 910 ? (
          <HomeBigSlider />
        ) : (
          <HomeSliderBigSizeMobile />
        )
      }
      <HomeRoadmap />
      <HomeMint />
      <HomeFaq />
      <HomeFooter />
    </div>
  )
}

export {
  HomePage
}
import React from 'react'
import {Navbar} from "../../components/navbar";
import c from './style.module.scss'
import {Wrap} from "../../components/wrappers/wrap";
import img from '../../assets/images/legion-big.png'
import {HomeFooter} from "../home/components/home-footer";



const PrivacyPage = () => {

  return (
    <div>
      <Navbar />
      <Wrap>
        <div className={c.container}>
          <img src={img} alt="logo" className={c.img} />
          <h2 className={c.termsTitle}>Privacy</h2>

          <div className={c.content}>
            <h4>Legion Universe Terms Overview:</h4>
            <p>
              We recognize that your privacy is very important and take
              it seriously. This Privacy Policy describes Legion Universe’s policies
              and procedures on the collection, use and disclosure of your information when you use
              any Legion Universe service, website, video game, interactive multimedia or any related
              website or social app. We will not use or share your information with anyone except as described in this Privacy Policy.
            </p>

            <h4>Information Collection and Use</h4>
            <p>
              We uses information we collect to analyze how our services ar used, diagnose service or technical problems, maintain security,
              personalize content, remember information to help you efficiently access your account, monitor aggregate metrics such as total
              number of visitors, traffic, and demographic patterns, and track
              User Content and users as necessary to comply with the Digital Millennium Copyright Act and other applicable laws.
            </p>

            <h4 className={c.small}>User-Provided Information:</h4>
            <p>
              If you provide us information about yourself, such as your name
              and e-mail address, if you register for a member account with any service related
              to Legion Universe. Your name and other information you choose to add to your profile
              will be available for public viewing on any of our services or related sites, apps or video games. We may use your email
              address to send you Service-related notices. You can opt out of any messages sent to you. We may also use your contact information
              to send you marketing messages. If you do not want to receive such messages, you may opt out by following the instructions in the message.
              If you correspond with us by email, we may retain the content of your email messages, your email address and our responses.
            </p>
            <p>
              You also provide us information in User Content you post to our services.
              Your posts and other contributions on any of our services or related sites, apps or
              video games, and metadata about them (such as when you posted them), are publicly viewable
              on any of our services or related sites, apps or video games, along with your name (unless any of
              our services or related sites, apps or video games permits you to post anonymously). This information may be searched by
              search engines and be republished elsewhere on the Web in accordance with our Terms of Service.
            </p>

            <h4 className={c.small}>Cookies</h4>
            <p>
              When you visit any of our services or related sites,
              apps or video games, we may send one or more “cookies” – small data files –
              to your computer to uniquely identify your browser and let Legion Universe help you log in faster and
              enhance your navigation through the site. A cookie may convey anonymous information about how you browse
              any of our services or related sites, apps or video games to us, but does not collect personal information about you.
              A persistent cookie remains on your computer after you close your browser so that it can be used by your browser on subsequent
              visits to any of our services or related sites, apps or video games. Persistent cookies can be removed by following your web browser’s directions.
              A session cookie is temporary and disappears after you close your browser. You can reset your web browser to refuse all cookies or to
              indicate when a cookie is being sent. However, some features of any of
              our services or related sites, apps or video games may not function properly if the ability to accept cookies is disabled.
            </p>

            <h4 className={c.small}>Log Files:</h4>
            <p>
              Log file information is automatically reported by your
              browser each time you access a web page. When you use any of our
              services or related sites, apps or video games, our servers automatically record certain
              information that your web browser sends whenever you visit any website. These server logs may include information such as your web request, Internet Protocol (“IP”) address, browser type, referring /
              exit pages and URLs, number of clicks, domain names, landing pages, pages viewed, and other such information.
            </p>

            <h4 className={c.small}>Third Party Services:</h4>
            <p>
              We may use third party services for the benefit of users.
              Such services will also have access to your information. We do not sell your information to third parties.
            </p>


            <h3>How We Share Your Information</h3>
            <h4 className={`${c.small} ${c.shareTitle}`}>Personally Identifiable Information:</h4>
            <p>
              We may share your personally identifiable information with third parties for
              the purpose of providing any of our services or related sites, apps or video games to you.
              If we do this, such third parties’ use of your information will be bound by this Privacy Policy.
              We may store personal information in locations outside the direct control of Legion Universe (for instance,
                on servers or databases co-located with hosting providers).
            </p>
            <p>
              We may share or disclose your information with your consent,
              such as if you choose to sign on to any of our services or related sites,
              apps or video games through a third-party service. We cannot control third parties’ use of your information.
            </p>
            <p>
              Legion Universe may disclose your personal information if required to do so by law or subpoena or if
              we believe that it is reasonably necessary to comply with a law, regulation or legal request; to protect the safety of any person;
              to address fraud, security or technical issues; or to protect Legion Universe’s rights or property.
            </p>

            <p>
              We may share non-personally identifiable information (such as anonymous usage data, referring/exit pages and URLs, platform types,
              number of clicks, etc.) with interested third parties to help them understand the usage patterns for certain Legion Universe services.
            </p>
            <p>
              Legion Universe currently does not allow third-party ad servers or ad networks to serve advertisements on any of our services or related sites, apps or video games.
            </p>

            <h4>How We Protect Your Information</h4>
            <p>
              We uses commercially reasonable physical, managerial, and technical safeguards to preserve the integrity and security
              of your personal information. We cannot, however, ensure or warrant the security of any information you transmit to Legion
              Universe or guarantee that your information on any of our services or related sites, apps or video games may not be accessed,
              disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.
            </p>

            <h4>Your Choices About Your Information</h4>
            <p>
              You may, of course, decline to submit personally identifiable information through any of our services or related sites,
              apps or video games, in which case Legion Universe may not be able to provide certain services to you. You may update or correct your
              account information and email preferences at any time by logging in to your account.
            </p>

            <h4 className={c.small}>Links to Other Web Sites</h4>
            <p>
              We are not responsible for the practices employed by websites linked to or from any of our services or related sites,
              apps or video games, nor the information or content contained therein. Please remember that when you use a link to go from any of our
              services or related sites, apps or video games to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website,
              including those that have a link on our website, is subject to that website’s own rules and policies.
            </p>

            <h4 className={c.small}>Changes to Our Privacy Policy</h4>
            <p>
              If we change our privacy policies and procedures, we will post those changes on this page
              to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it.
              Changes to this Privacy Policy are effective when they are posted on this page.
            </p>

            <div className={c.date}>Last updated on: February 11, 2023</div>
          </div>
        </div>

        <HomeFooter />
      </Wrap>
    </div>
  )
}

export {
  PrivacyPage
}
import React, {useEffect, useRef, useState} from 'react'
import c from './style.module.scss'
import backgroundImg from "../../../../assets/images/universe-bg.png";
import {Wrap} from "../../../../components/wrappers/wrap";
import titleImageFirst from '../../../../assets/images/universe-text-first.png'
import titleImageSecond from '../../../../assets/images/universe-text-second.png'
import greenBg from '../../../../assets/images/green-bg.png'
import icon1 from '../../../../assets/images/svg/vector-vip.svg'
import icon2 from '../../../../assets/images/svg/vector-person.svg'
import icon3 from '../../../../assets/images/svg/vector-cube.svg'
import icon4 from '../../../../assets/images/svg/vecror-star.svg'
import icon5 from '../../../../assets/images/svg/vector-peoples.svg'
import {AnimateHoc} from "../../../../components/animate-hoc";
import {OpacityHoc} from "../../../../components/opacity-hoc";

const benefitsMock = [
  {
    icon: icon1,
    text: "VIP access to the film productions"
  },
  {
    icon: icon2,
    text: "Opportunity to be first to own comic books"
  },
  {
    icon: icon3,
    text: "VIP access to apps and video games"
  },
  {
    icon: icon4,
    text: "Participation in events and promotions"
  },
  {
    icon: icon5,
    text: "Participation in the Legion community and opportunity to vote"
  },
]

const HomeUniverse = () => {
  const [isInView, setIsInView] = useState(false)
  const [isBenefitsInView, setIsBenefitsInView] = useState(false)
  const benefitsRef = useRef()
  const ref = useRef()

  useEffect(() => {
    if (isInView) {
      window.removeEventListener("scroll", scrollListener)
    } else {
      window.addEventListener("scroll", scrollListener)
    }
  }, [isInView])

  useEffect(() => {
    if (isInView) {
      window.removeEventListener("scroll", scrollListenerBenefits)
    } else {
      window.addEventListener("scroll", scrollListenerBenefits)
    }
  }, [isInView])

  const scrollListener = () => {
    if ((ref.current?.getBoundingClientRect().top - window.innerHeight) < -100) {
      setIsInView(true)
    }
  }

  const scrollListenerBenefits = () => {
    if ((benefitsRef.current?.getBoundingClientRect().top - window.innerHeight) < -100) {
      setIsBenefitsInView(true)
    }
  }

  return (
    <div
      style={{ backgroundImage: `url("${backgroundImg}")`}}
      className={c.wrap}
    >
      <div className={c.gradient} />
      <Wrap>
        <div className={c.content} ref={ref}>
          <AnimateHoc isInView={isInView} isLeft={true} translateValue={200} start={100}>
            <div className={c.titleFirstWrap}>
              <img src={titleImageFirst} alt="legal-universe"/>

              <div>
                <div className={c.firstDec} />
              </div>
            </div>
          </AnimateHoc>

          <AnimateHoc isInView={isInView} isLeft={true} translateValue={-200} start={300}>
            <div className={c.titleSecondWrap}>
              <div>
                <div className={c.firstDec} />
              </div>

              <img src={titleImageSecond} alt="dtc-benefits"/>
            </div>
          </AnimateHoc>



          <div className={c.list} ref={benefitsRef}>
            {
              benefitsMock.map((item, index) => (
                <OpacityHoc key={Math.random()} isInView={isBenefitsInView} start={(index + 1) * 200}>
                  <div className={c.item}>
                    <div className={c.itemIconWrap}>
                      <img src={greenBg} alt="green-back"/>
                      <img src={item.icon} alt=""/>
                    </div>

                    <p className={c.itemText}>{item.text}</p>
                  </div>
                </OpacityHoc>
              ))
            }
          </div>
        </div>
      </Wrap>
    </div>
  )
}


export {
  HomeUniverse
}
import React from 'react'
import c from './style.module.scss'
import partnerDec from '../../../../assets/images/parners-deg.png'
import partnerTextImg from '../../../../assets/images/partners-text.png'
import logo1 from '../../../../assets/images/bob-logo.png'
import logo2 from '../../../../assets/images/dima-logo.png'
import logo3 from '../../../../assets/images/bit-logo.png'
import logo4 from '../../../../assets/images/glg-logo.png'
import logo5 from '../../../../assets/images/sportsmens-logo.png'
import whiteBg from '../../../../assets/images/white-bg.png'

const logos = [
  logo1,logo2,logo3,logo4,logo5
]

const PartnerBlock = () => {

  return (
    <div>
      <div className={c.partnerWrap}>
        <img className={c.partnerDec} src={partnerDec} alt=""/>
        <img src={partnerTextImg} alt=""/>
      </div>
    </div>

  )
}

const HomePartners = () => {

  return (
    <div>
      <div className={c.wrap}>
        <PartnerBlock />
        {
          logos.map(item => (
            <div key={Math.random()} className={c.logoItem}>
              <div className={c.logoMock} />

              <img className={c.dec} src={whiteBg} alt="white-back"/>
              <img src={item} alt=""/>
            </div>
          ))
        }

      </div>
    </div>
  )
}


export {
  HomePartners
}
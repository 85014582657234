import React from 'react'
import {Navbar} from "../../components/navbar";
import c from './style.module.scss'
import {Wrap} from "../../components/wrappers/wrap";
import img from '../../assets/images/legion-big.png'
import {HomeFooter} from "../home/components/home-footer";



const LegalPage = () => {

  return (
    <div>
      <Navbar />
      <Wrap>
        <div className={c.container}>
          <img src={img} alt="logo" className={c.img} />
          <h2 className={c.termsTitle}>Legal</h2>

          <div className={c.content}>
            <div>
              <h4>Legion Universe Legal Overview:</h4>
              <p>
                We follow all laws regarding the creation of the projects of the Legion Universe.
                We also follow all securities laws in all jurisdictions in which our products are used or sold.
              </p>

            </div>
            <div>
              <h4>Legion Universe NFT holder rights:</h4>
              <p>
                Due to the fact that the Legion Universe NFTs are concept art of feature film intellectual properties,
                purchasers of the Legion Universe NFTs are granted personal non-commercial use (including use on all social media platforms and personal websites) and resale
                rights in the NFT. Purchasers have no right to license, commercially exploit, reproduce, distribute or prepare
                derivative works without written authorization. All copyright and other rights are reserved and not granted or sold.
              </p>
            </div>

            <div>
              <h4>Changes to Our Privacy Policy</h4>
              <p>
                If we change our legal policies and procedures related to the Legion Universe NFTs, we will post those changes on
                this page to keep you aware of all legal aspects of Legion Universe NFT ownership. Changes to this Legal section
                are effective when they are posted on this page.
              </p>
            </div>


            <div className={c.date}>Last updated on: February 11, 2023</div>
          </div>
        </div>

        <HomeFooter />
      </Wrap>
    </div>
  )
}

export {
  LegalPage
}
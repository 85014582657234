import React from 'react'
import c from './style.module.scss'
import video from './Legion_Team.mp4'


const VideoComponent = ({closeHandler}) => {

  return (
    <div className={c.wrap} onClick={() => closeHandler()}>
      <video autoPlay width="100%" controls={false}>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  )
}

export {
  VideoComponent
}
import React from 'react'
import c from './style.module.scss'
import subscribeIcon from '../../../assets/images/svg/subscribe.svg'
import greenIcon from '../../../assets/images/green-bg.png'


const ButtonSubscribe = ({text}) => {


  return (
    <button className={c.btn}>
      <div className={c.dec} />
      <span className={c.iconWrap}>

        <img className={c.subscribeIcon} src={subscribeIcon} alt="subscribe"/>
        <img className={c.greenIcon} src={greenIcon} alt="green-back"/>
      </span>

      <span className={c.text}>
        {text}
      </span>
    </button>
  )
}

export {
  ButtonSubscribe
}
import React from 'react'
import c from './style.module.scss'


const Wrap = ({children, maxWidth}) => {

  return (
    <div className={c.wrap} style={{maxWidth: maxWidth ? maxWidth : '1200px'}}>
      {children}
    </div>
  )
}


export {
  Wrap
}
import React, {useEffect, useRef, useState} from 'react'
import c from './style.module.scss'
import bonusBgImg from "../../../../assets/images/bonus-icon-bg.png";
import {AnimateHoc} from "../../../../components/animate-hoc";


const HomeBonusItem = ({item}) => {
  const [isBonusInView, setIsBonusInView] = useState(false)
  const bonusRef = useRef()

  useEffect(() => {
    if (isBonusInView) {
      window.removeEventListener("scroll", scrollListener)
    } else {
      window.addEventListener("scroll", scrollListener)
    }
  }, [isBonusInView])

  const scrollListener = () => {
    if(isBonusInView) return
    if ((bonusRef.current?.getBoundingClientRect().top - window.innerHeight) < -100) {
      setIsBonusInView(true)
    }
  }

  return (
    <div ref={bonusRef} className={c.bonusItem}>
      <div className={c.bonusIconWrap}>
        <img className={c.bonusIconBg} src={bonusBgImg} alt=""/>
        <img src={item.icon} alt=""/>
      </div>

      <h4 className={c.bonusTitle}>{item.title}</h4>

      <div className={c.bonusList}>
        {
          item.terms.map((listItem, index) => (
            <AnimateHoc key={item.title + listItem} isInView={isBonusInView} start={(index + 1) * 200} translateValue={300}>
              <div className={c.bonusListItem}>
                        <span className={c.bonusListCount}>
                          0{index+1}
                        </span>
                <p className={c.bonusListText}>
                  {listItem}
                </p>
              </div>
            </AnimateHoc>
          ))
        }
      </div>
    </div>
  )
}

export {
  HomeBonusItem
}
import React from 'react'
import c from './style.module.scss'
import img from '../../../assets/images/svg/button.svg'
import arrowImg from '../../../assets/images/svg/arrow.svg'
import lineLeft from '../../../assets/images/line-left.png'
import lineRight from '../../../assets/images/line-right.png'


const ButtonDefault = ({text, isIconShow}) => {
  return (
    <a href="http://mint.legionuniverse.com/">
      <div className={c.wrap}>
        <img className={c.lineLeft} src={lineLeft} alt="line-left" />
        <img className={c.lineRight}  src={lineRight} alt="line-right" />
        <img className={c.img} src={img} alt="button"/>
        <div className={c.dec} />

        <button className={c.btn}>
        <span>
          {text}
        </span>
          {isIconShow && <img alt="arrow" src={arrowImg} />}
        </button>
      </div>
    </a>
  )
}

export {
  ButtonDefault
}
import React from 'react'
import c from './style.module.scss'
import backgroundImg from '../../../../assets/images/home-background.png'
import {Wrap} from "../../../../components/wrappers/wrap";
import {ButtonDefault} from "../../../../components/buttons/button-default";
import {AnimateHoc} from "../../../../components/animate-hoc";
import {HomeHoverCard} from "../home-hover-card";


const HomePreview = ({windowSize}) => {

  return (
    <div
      className={c.wrap}
      style={{backgroundImage: `url("${backgroundImg}")`}}
    >
      <div className={c.container}>
        <Wrap maxWidth="1200px">
          <div className={c.content}>
            <div className={c.information}>
              <AnimateHoc isInView={true} start={10} translateValue={100} transitionValue={1}>
                <h2 className={c.title}>
                  <span className={c.green}>Next</span>-Generation Superheroes
                </h2>
              </AnimateHoc>

              <AnimateHoc isInView={true} start={200} translateValue={200} transitionValue={1}>
                <p className={c.text}>
                  Five super-powered film franchises, one universe, from a team that has worked on Avatar, Avengers,
                  and with Stan Lee. Get VIP privileges in films, video games, and comic books with your Polygon Legion
                  Universe NFT
                </p>
              </AnimateHoc>

              <AnimateHoc isInView={true} start={300} translateValue={300} transitionValue={1}>
                <div className={c.btnWrap}>
                  <ButtonDefault text="Mint Now" isIconShow={true}/>
                </div>
              </AnimateHoc>


            </div>

            <div >
              <HomeHoverCard windowSize={windowSize} />
            </div>
          </div>
        </Wrap>
      </div>
    </div>
  )
}


export {
  HomePreview
}
import React, {useEffect, useRef, useState} from 'react'
import c from './style.module.scss'
import backgroundImg from "../../../../assets/images/home-createion.png";
import creationTitleImg from "../../../../assets/images/creation-text.png";
import posterFirst from "../../../../assets/images/creation-poster-first.jpg";
import posterSecond from "../../../../assets/images/creation-poster-second.jpg";
import poster2First from "../../../../assets/images/slider-fist-1.jpg";
import poster2Second from "../../../../assets/images/slider-fist-2.jpg";
import poster3First from "../../../../assets/images/slider-second-1.png";
import poster3Second from "../../../../assets/images/slider-second-2.jpg";
import poster4First from "../../../../assets/images/slider-third-1.jpg";
import poster4Second from "../../../../assets/images/slider-third-2.jpg";
import {Wrap} from "../../../../components/wrappers/wrap";
import coolIcon from '../../../../assets/images/svg/vector-cool.svg'
import {OfferWrap} from "../../../../components/wrappers/offer-wrap";

const creationList = [
  {
    title: "In images",
    text: "Each character from each franchise has many concept images that range from common to legendary to rare.",
    posters: {
      first: posterFirst,
      second: posterSecond
    }
  },
  {
    title: "In colors",
    text: "There are seven colors for the frames of the NFTs and one without color. The most rare colors are Gold and Silver.",
    posters: {
      first: poster2First,
      second: poster2Second
    }
  },
  {
    title: "In symbols",
    text: "There are twelve symbols on the top left corner of each Legion Universe NFT. These symbols are ranked according to rarity.",
    posters: {
      first: poster3First,
      second: poster3Second
    }
  },
  {
    title: "In symbols",
    text: "As a special bonus we created five ULTRA RARE Legion Universe NFTs. These NFTs are GOLD editions of the main characters of each franchise.",
    posters: {
      first: poster4First,
      second: poster4Second
    }
  },
]

const HomeCreation = ({windowSize, monetPrice}) => {
  const [isClicked, setIsClicked] = useState(false)
  const [activeValue, setActiveValue] = useState(1)
  const [isInView, setIsInView] = useState(false)
  const ref = useRef()
  const [sliderValue, setSliderValue] = useState(1)

  const changeSlide = () => {
   setTimeout(() => {
     if(isClicked) return
     if (sliderValue === 4) {
       setSliderValue(1)
     } else setSliderValue(activeValue + 1)
   }, 2000)
  }

  useEffect(() => {
    if(isClicked) return
    changeSlide()
  }, [activeValue])

  useEffect(() => {
    if(isClicked) return
    setActiveValue(sliderValue)
  }, [sliderValue])

  useEffect(() => {
    if (isInView) {

      window.removeEventListener("scroll", scrollListener)
    } else {
      window.addEventListener("scroll", scrollListener)
    }
  }, [isInView])


  const scrollListener = () => {
    if ((ref.current?.getBoundingClientRect().top - window.innerHeight) < -100) {
      setIsInView(true)
    }
  }

  return (
    <div className={c.block}>
      <div
        style={{ backgroundImage: `url("${backgroundImg}")`}}
        className={c.wrap}
      >
        <div className={c.gradient} />
        <div className={c.container}>
          <Wrap>
            <div className={c.content}>
              <div className={c.postersWrap}>
                <img className={c.creationTitleImg} src={creationTitleImg} alt="creation"/>
                <p className={c.postersText}>
                  Thousands of concept images of characters in
                  the five initial film franchises were created by Artificial
                  Intelligence and enhanced and touched up by our artists. The best were
                  chosen to be part of the Legion Universe collection. Rarity is extremely important to us and we embedded rarity factors in many ways.
                </p>

                <div className={c.postersContainer}>
                  <div>
                    <img className={c.posterFirst} src={creationList[activeValue - 1].posters.first} alt="poster-men"/>
                  </div>
                  <div>
                    <img className={c.posterSecond} src={creationList[activeValue - 1].posters.second} alt="poster-women"/>
                  </div>
                </div>
              </div>

              <div className={c.creationListWrap}>
                <div className={c.creationList}>
                  {
                    creationList.map((listItem, index) => (
                      <div
                        key={listItem.text}
                        className={`${c.creationListItem} ${index + 1 === activeValue ? c.creationListItemActive : ''}`}
                        onClick={() => {
                          setIsClicked(true)
                          setActiveValue(index + 1)
                        }}
                      >
                        <div>
                          <div className={c.iconWrap}>
                            <img src={coolIcon} alt=""/>
                          </div>
                        </div>

                        <div className={c.listItemContent}>
                          <h5>{listItem.title}</h5>
                          <p>{listItem.text}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </Wrap>
        </div>
      </div>

      <div className={isInView ? c.offerActive : c.offer} ref={ref} >
        <Wrap>
          <OfferWrap
            windowSize={windowSize}
            text="This is your only chance to acquire this exclusivelegion digital collectible for only 80 MATIC "
            price={monetPrice}
          />
        </Wrap>
      </div>
    </div>

  )
}


export {
  HomeCreation
}
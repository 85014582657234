import React from 'react'
import c from './style.module.scss'
import backgroundImg from "../../../../assets/images/home-about-bg.jpg";
import {HomeSliderBigSize} from "../home-slider-bg-size";


const HomeBigSlider = () => {

  return (
    <div
      className={c.wrap}
      style={{ backgroundImage: `url("${backgroundImg}")`}}
    >
      <div className={c.gradient}>
        <HomeSliderBigSize />
      </div>
    </div>
  )
}


export {
  HomeBigSlider
}
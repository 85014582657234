import React, {useEffect, useState} from 'react'
import c from './style.module.scss'
import img1 from '../../../../assets/images/details-card1.png'
import img2 from '../../../../assets/images/details-card2.png'
import img3 from '../../../../assets/images/details-card3.png'
import img4 from '../../../../assets/images/details-card4.png'
import img5 from '../../../../assets/images/details-card5.png'
import img6 from '../../../../assets/images/details-card6.png'
import img7 from '../../../../assets/images/details-card7.png'

const cardsMock = [
  img1,img2,img3,img4,img5,img6,img7
]

const data = [
  ...cardsMock, ...cardsMock
]

const HomeDetailsCards = () => {
  const [marginLeft, setMarginLeft] = useState(0)

  useEffect(() => {
    if (marginLeft === -2195000 + 2195) {
      setTimeout(() => {
        setMarginLeft(0)
      }, 10)
    } else {
      setTimeout(() => {
        setMarginLeft(prev => prev - 2195)
      }, 10)
    }
  }, [marginLeft])



  return (
    <div className={c.section}>
      <div style={{marginLeft: `${marginLeft / 1000}px`}} className={c.wrap}>
        {
          data.map((item, index) => (
            <img key={index} src={item} alt=""/>
          ))
        }
      </div>
    </div>
  )
}

export {
  HomeDetailsCards
}
import React, {useEffect, useRef, useState} from 'react'
import c from './style.module.scss'
import decImg from "../../../../assets/images/light-green-dec.png";
import blocKBg from "../../../../assets/images/block-white-bg-bordered.png";
import {AnimateHoc} from "../../../../components/animate-hoc";

const HomeCategoriesListItem = ({isReverse, item}) => {
  const blockClass = isReverse ? `${c.blockBg}` : `${c.blockBg} ${c.blockBgReverse}`
  const decClass = isReverse ? `${c.dec}` : `${c.dec} ${c.decReverse}`
  const itemWrapClass = isReverse ? `${c.itemImageWrap}` : `${c.itemImageWrap} ${c.itemImageWrapReverse}`
  const [isInView, setIsInView] = useState(false)
  const ref = useRef()

  useEffect(() => {
    if (isInView) {
      window.removeEventListener("scroll", scrollListener)
    } else {
      window.addEventListener("scroll", scrollListener)
    }
  }, [isInView])

  const scrollListener = () => {
    if ((ref.current?.getBoundingClientRect().top - window.innerHeight) < -100) {
      setIsInView(true)
    }
  }

  return (
    <div className={c.itemSection} ref={ref}>
        <div className={c.itemBlock}>
          <AnimateHoc isInView={isInView} start={0} translateValue={isReverse ? -200 : 200} isLeft={true}>

          <div className={c.itemWrap}>
            <img className={decClass} src={decImg} alt=""/>
            <img className={blockClass} src={blocKBg} alt=""/>
            <div className={c.itemContainer}>
              <div className={c.itemContent}>
                <h4>{item.title}</h4>

                <p>
                  {item.text}
                </p>
              </div>


              <div className={itemWrapClass}>
                <img src={item.image} alt=""/>
              </div>
            </div>
          </div>
          </AnimateHoc>

        </div>
    </div>
  )
}

export {
  HomeCategoriesListItem
}
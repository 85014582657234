import React from 'react'
import c from './style.module.scss'
import {Wrap} from "../../../../components/wrappers/wrap";
import poster1 from '../../../../assets/images/cat-car.jpg'
import poster2 from '../../../../assets/images/cat-comic.jpg'
import poster3 from '../../../../assets/images/cat-game.jpg'
import poster4 from '../../../../assets/images/cat-premier.jpg'
import {HomeCategoriesListItem} from "../home-categories-list-item";

export const mockCategoriesList = [
  {
    title: "Film Productions",
    text: `
      You will have access to cast and crew interviews, be able to see the day-to-day activities and even participate in the 
      creative aspects including outfit of characters, designs of creatives and sets through your ability to vote.
    `,
    image: poster1
  },
  {
    title: "Comic Books",
    text: `
      You will have access to early artwork and be able 
      to see the comic book production pipeline. You will also have the opportunity to be among the first to purchase each of the comic books.
    `,
    image: poster2
  },

  {
    title: "Video Games",
    text: `
      Get ready for battle! You will be part of the creation and get VIP privileges inside the games.
    `,
    image: poster3
  },
  {
    title: "Events & Promotions",
    text: `
      You will have the opportunity to be involved in events and promotions as well as early access to merchandise.
    `,
    image: poster4
  },
]

const HomeCategoriesList = () => {


  return (
    <div className={c.wrap}>
      <Wrap>
        <div className={c.list}>
          {
            mockCategoriesList.map((item, index) => (
              <HomeCategoriesListItem item={item} isReverse={index % 2 === 0} key={item.title} />
            ))
          }
        </div>
      </Wrap>
    </div>
  )
}


export {
  HomeCategoriesList
}
import React, {useEffect, useRef, useState} from 'react'
import c from './style.module.scss'
import {Wrap} from "../../../../components/wrappers/wrap";
import infoBg  from '../../../../assets/images/home-info-bg.jpg'
import childrenImg  from '../../../../assets/images/cool-child.png'
import menImg from '../../../../assets/images/cool-men.png'
import {OfferWrap} from "../../../../components/wrappers/offer-wrap";
import {AnimateHoc} from "../../../../components/animate-hoc";



const HomeOffer = ({windowSize, monetPrice}) => {
  const [isInView, setIsInView] = useState(false)
  const ref = useRef()
  const [isBonusInView, setIsBonusInView] = useState(false)
  const bonusRef = useRef()

  useEffect(() => {
    if (isBonusInView) {
      window.removeEventListener("scroll", scrollListenerBonus)
    } else {
      window.addEventListener("scroll", scrollListenerBonus)
    }
  }, [isBonusInView])

  useEffect(() => {
    if (isInView) {
      window.removeEventListener("scroll", scrollListener)
    } else {
      window.addEventListener("scroll", scrollListener)
    }
  }, [isInView])

  const scrollListenerBonus = () => {
    if ((bonusRef.current?.getBoundingClientRect().top - window.innerHeight) < -100) {
      setIsBonusInView(true)
    }
  }

  const scrollListener = () => {
    if ((ref.current?.getBoundingClientRect().top - window.innerHeight) < 200) {
      setIsInView(true)
    }

  }

  const offerClass = isInView ? `${c.offer} ${c.offerActive}` : c.offer

  return (
    <Wrap>
      <div className={c.sections}>
        <div ref={ref} className={offerClass}>
          <OfferWrap
            windowSize={windowSize}
            text="Seize this unparalleled opportunity to claim your very own, exceptionally rare, limitededition treasure for 80 MATIC"
            price={monetPrice}
          />
        </div>

        <div
          className={c.info}
          style={{ backgroundImage: `url("${infoBg}")`}}
          ref={bonusRef}
        >
          <img src={menImg} className={c.men} alt="cool-men"/>
          <img src={childrenImg} className={c.child} alt="cool-men"/>
          <div className={c.infoContainer}>
            <div className={c.infoContent}>
              <AnimateHoc
                isInView={isBonusInView}
                start={50}
                translateValue={100}
              >
                <h3 className={c.infoTitle}>
                  Buy 50 Digital Trading Cards
                </h3>
              </AnimateHoc>


              <AnimateHoc
                isInView={isBonusInView}
                start={300}
                translateValue={-100}
                isLeft={true}
              >
                <p className={c.textInfo}>
                  You are guaranteed to receive an opportunity for a <span className={c.greenBorder}>CAMEO ROLE </span> in
                  one of the films as well as an invitation to one of the <span className={c.greenBorder}>RED CARPET SCREENINGS!</span>
                </p>
              </AnimateHoc>

              <AnimateHoc
                isInView={isBonusInView}
                start={500}
                translateValue={100}
                isLeft={true}
              >
                <p className={c.textInfo2}>
                  PLUS an in person dinner with producers, directors, writers and VFX. <span className={c.greenBorder}>PLUS</span> with the investment club, access to information,
                  investments and funds that are not available to the general public.
                </p>
              </AnimateHoc>
            </div>
          </div>
        </div>
      </div>
    </Wrap>
  )
}


export {
  HomeOffer
}
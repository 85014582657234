import React, {useCallback, useRef, useState} from 'react'
import c from './style.module.scss'
import greenBg from '../../../../assets/images/slider-big-size-mobile-bg.png'
import arrowRight from '../../../../assets/images/svg/arrow.svg'

import universeText from '../../../../assets/images/the-universe-text.png'

import poster1 from '../../../../assets/images/posters/big-poster-legion.png'
import poster2 from '../../../../assets/images/posters/big-poster-powers.png'
import poster3 from '../../../../assets/images/posters/big-poster-qlentin.png'
import poster4 from '../../../../assets/images/posters/big-poster-speed.png'
import poster5 from '../../../../assets/images/posters/big-poster-discovery.png'

const sliders = [
  {
    type: 'unique',
    title: "",
    text: `
      We have created a new Super-Powered Universe with an initial 5 feature films. 
      We plan to grow and expand like the Marvel Universe and bring this new Universe to theater screens, 
      computer screens, tablet screens and to your phone. Join us as we create the films, video games, comic books, 
      novels, augmented reality, virtual reality, NFTs and step into the metaverse!
    `,
    img: universeText
  },
  {
    title: "Legion",
    text: `
      A scientist working on the creation of super-soldiers experiments on prisoners and succeeds. In 
      the military struggle to stop the super villains, five young students are exposed 
      and develop super powers. The young heroes struggle with life while they are assembled as the only hope to regain control.
    `,
    img: poster1
  },
  {
    title: "Powers of the past",
    text: `
      Bree Powers, a teenager, is struck by lightning and goes into a coma. Two days later she wakes in a hospital bed, 
      but has been living the life of an ancient magic user’s apprentice for many years. Her connection to the apprentice 
      brings magic back and dangers the world has not faced for over 4000 years.
    `,
    img: poster2
  },
  {
    title: "Quentin hollow",
    text: `
      Bree Powers, a teenager, is struck by lightning and goes into a coma. Two days later she
       wakes in a hospital bed, but has been living the life of an ancient magic user’s apprentice for many years. 
        Her connection to the apprentice brings magic back and dangers the world has not faced for over 4000 years.
    `,
    img: poster3
  },
  {
    title: "Speed of Light",
    text: `
      Traveling the speed of light traumatizes Pierce Black and he falls into a coma. 
      When he wakes his mind is no longer trapped by time and he has enhanced physical abilities. As he learns to control his 
      new abilities he discovers his wife’s death was not an accident and her killers are after him.
    `,
    img: poster4
  },
  {
    title: "Discovery",
    text: `
      Devon is convinced to join an expedition in South America which has uncovered an ancient library 
      with records describing incredible technologies of the past. Devon translates writings describing immortality 
      and advanced technologies and unlocks a force that could destroy the civilized world.
    `,
    img: poster5
  }
]

const HomeSliderBigSizeMobile = () => {
  const [activeSliderNumber, setActiveSliderNumber] = useState(0)
  const [sliderLeft, setSliderLeft] = useState(0)
  const containerRef = useRef()
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setSliderLeft(node.getBoundingClientRect().left);
    }
  }, []);

  const changeSliderValue = (type) => {
    let newValue
    if (type === 'add') {
      if (activeSliderNumber + 1 > sliders.length - 1) return
      newValue = activeSliderNumber + 1

      let count = 1;
      const slideTimer = setInterval(function () {
        if (count === 11) {
          window.clearInterval(slideTimer);
          return
        }
        containerRef.current.scrollLeft = activeSliderNumber * 300 + (count * 30)
        count++;
      }, 20);

    } else {
      if (activeSliderNumber - 1 < 0) return
      newValue = activeSliderNumber - 1

      let count = 1;
      const slideTimer = setInterval(function () {
        if (count === 11) {
          window.clearInterval(slideTimer);
          return
        }
        containerRef.current.scrollLeft = activeSliderNumber * 300 - (count * 30)
        count++;
      }, 20);
    }

    setActiveSliderNumber(newValue)

  }


  return (
    <div className={c.wrap}>
      <div ref={measuredRef} className={c.headerWrap}>
        <div className={c.header}>
          <div
            className={c.line}
          >
            <div
              className={c.lineActive}
              style={{
                width: `${100 / sliders.length}%`,
                marginLeft: `${100 / sliders.length * activeSliderNumber}%`
              }}
            />
          </div>

          <div className={c.buttons}>
            <img
              className={c.arrowLeft}
              src={arrowRight}
              alt="arrow-left"
              onClick={() => changeSliderValue("")}
            />
            <img
              className={c.arrowRight}
              src={arrowRight}
              onClick={() => changeSliderValue("add")}
              alt="arrow-right"
            />
          </div>
        </div>
      </div>

      <div className={c.container} style={{paddingLeft: `${sliderLeft + 20}px`}} ref={containerRef}>
        <div className={c.list}>
          {
            sliders.map(item => (
              <React.Fragment key={Math.random()}>
                {item.type === 'unique' ? (
                  <div className={c.item}>
                    <img className={`${c.sliderImg} ${c.blackBg}`} src={greenBg} alt=""/>
                    <div className={c.sliderContainer}>
                      <div>
                        <img className={c.universeTextImg} src={item.img} alt=""/>
                        <p className={c.universeText}>{item.text}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={c.item}>
                    <img className={`${c.sliderImg} ${c.blackBg}`} src={greenBg} alt=""/>

                    <div className={c.sliderContainer}>
                      <div className={c.smallContainer}>
                        <div className={c.sliderContent}>
                          <h4>{item.title}</h4>
                        </div>
                        <img className={c.sliderContainerPoster} src={item.img} alt=""/>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))
          }
        </div>
      </div>
    </div>
  )
}


export {
  HomeSliderBigSizeMobile
}
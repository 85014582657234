import React, {useState} from 'react'
import c from './style.module.scss'

const ToggleIcon = ({isActive, submit}) => {
  const iconClass = isActive ? `${c.icon} ${c.iconRotate}` : c.icon

  return (
    <div onClick={submit} className={iconClass}>
      <div />
      <div />
    </div>
  )
}

const HomeFaqItem = ({item}) => {
  const [isActive, setIsActive] = useState(false)
  const descriptionClass = isActive ? `${c.description} ${c.descriptionActive}` : c.description
  const toggleActive = () => setIsActive(!isActive)

  return (
    <div className={c.wrap}>
      <div className={c.header}>
        <p className={c.title}>{item.title}</p>

        <ToggleIcon submit={toggleActive} isActive={isActive} />
      </div>
      <div className={descriptionClass}>
        <p>
          {item.description}
        </p>
      </div>
    </div>
  )
}


export {
  HomeFaqItem
}
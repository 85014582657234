import React, {useEffect, useState} from 'react'
import c from './style.module.scss'
import usaIcon from '../../../assets/images/countries/button_USA.svg'
import thailandIcon from '../../../assets/images/countries/button_Thailand.svg'
import vietnamIcon from '../../../assets/images/countries/button_Vietnam.svg'
import chinaIcon from '../../../assets/images/countries/button_China.svg'
import japanIcon from '../../../assets/images/countries/button_Japan.svg'
import koreaIcon from '../../../assets/images/countries/button_Korea.svg'
import rIcon from '../../../assets/images/countries/button_r.svg'
import {IoIosArrowDown} from "react-icons/io";

const options = [
  {label: "usa", icon: usaIcon},
  {label: "thailand", icon: thailandIcon},
  {label: "vietnam", icon: vietnamIcon},
  {label: "china", icon: chinaIcon},
  {label: "japan", icon: japanIcon},
  {label: "korea", icon: koreaIcon},
  {label: "r", icon: rIcon},
]

const CountrySelect = () => {
  const [isActive, setIsActive] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(options[0]);


  const optionsValue = options.filter(item => item.label !== selectedCountry?.label)

  const submitHandler = (e) => {
    if (e.target.getAttribute("data-text") !== 'slider_close') {
     setIsActive(false)
    }
  }


  useEffect(() => {
    if (isActive) {
      window.addEventListener("click", submitHandler)
    } else {
      window.removeEventListener("click", submitHandler)
    }

  }, [isActive])



  return (
    <div className={c.container} data-text="slider_close">
      <div className={c.wrap} data-text="slider_close" style={{maxHeight: isActive ? "500px" : "47px"}}>
        <div className={c.header} data-text="slider_close" onClick={() => setIsActive(!isActive)}>
        <span className={c.value} data-text="slider_close">
          <img data-text="slider_close" src={selectedCountry.icon} alt=""/>
        </span>

          <span data-text="slider_close">
            <IoIosArrowDown
              data-text="slider_close"
              style={{transition: '0.3s', transform: `rotate(${isActive ? 180 : 0}deg)`}}
            />
          </span>
        </div>

        <div className={c.list} data-text="slider_close">
          {
            optionsValue.map((item, index) => (
              <div
                key={item.label}
                onClick={() => {
                    setSelectedCountry(options[index])
                    setIsActive(false)
                  }
                }
                data-text="slider_close"
              >
                <img data-text="slider_close" src={item.icon} alt=""/>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export {
  CountrySelect
}
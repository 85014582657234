import React from 'react'
import c from './style.module.scss'
import {Wrap} from "../../../../components/wrappers/wrap";
import creationText from '../../../../assets/images/creation-mobile-text.png'
import posterSecond from "../../../../assets/images/creation-poster-second.jpg";
import imgCool from '../../../../assets/images/svg/vector-cool.svg'
import poster2Second from "../../../../assets/images/slider-fist-2.jpg";
import poster3Second from "../../../../assets/images/slider-second-2.jpg";
import poster4Second from "../../../../assets/images/slider-third-2.jpg";

const mockData = [
  {
    title: "In images",
    text: "Each character from each franchise has many concept images that range from common to legendary to rare.",
    img: posterSecond
  },
  {
    title: "In colors",
    text: "There are seven colors for the frames of the NFTs and one without color. The most rare colors are Gold and Silver.",
    img: poster2Second
  },
  {
    title: "In symbols",
    text: "There are twelve symbols on the top left corner of each Legion Universe NFT. These symbols are ranked according to rarity.",
    img: poster3Second
  },
  {
    title: "In symbols",
    text: "As a special bonus we created five ULTRA RARE Legion Universe NFTs. These NFTs are GOLD editions of the main characters of each franchise.",
    img: poster4Second
  }
]

const HomeCreationMobile = () => {

  return (
    <div className={c.wrap}>

      <Wrap>
        <img className={c.creationText} src={creationText} alt=""/>
        <p className={c.text}>
          Thousands of concept images of characters in the five initial film
          franchises were created by Artificial Intelligence and enhanced and touched
          up by our artists. The best were chosen to be part of the Legion Universe collection. Rarity
          is extremely important to us and we embedded rarity factors in many ways.
        </p>

        <div className={c.list}>
          {
            mockData.map(item => (
              <div key={Math.random()} className={c.item}>
                <div className={c.header}>
                  <div className={c.iconWrap}>
                    <img src={imgCool} alt=""/>
                  </div>

                  <h4 className={c.itemTitle}>{item.title}</h4>
                </div>

                <p className={c.itemText}>
                  {item.text}
                </p>
                <img className={c.poster} src={item.img} alt=""/>
              </div>
            ))
          }
        </div>
      </Wrap>
    </div>
  )
}


export {
  HomeCreationMobile
}
import React, {useEffect} from 'react'
import './style.scss'
import cardImg from '../../../../assets/images/home-card.jpg'

const HomeHoverCard = ({windowSize}) => {

  function startHover () {
    let card = document.getElementById('card'),
    gloss = card.querySelector('.card__gloss'),
    width = window.innerWidth,
    height = window.innerHeight;

    function init() {
      bindMouse();

      // Initial tilt
      light(350, -125);
      tilt(350, -125);
    }

    function bindMouse() {
      document.addEventListener('mousemove', (event) => {
        let x = event.clientX - (width / 2),
          y = event.clientY - (height / 2);

        light(x, y);
        tilt(x, y);
      });
    }

    function light(x, y) {
      let angle = (Math.atan2(y, x) * 180) / Math.PI - 90;

      gloss.style.background = 'linear-gradient(' + angle + 'deg, rgba(255, 255, 255,' + y / height * .9 + ') 0%, rgba(255, 255, 255, 0) 80%)';
    }

    function tilt(x, y) {
      let force = 80,
        rx = (x / width) * force,
        ry = (y / height) * -force;

      card.style.transform = 'rotateY(' + (rx) + 'deg) rotateX(' + (ry) + 'deg)';
      // content.style.transform = 'translateX(' + (rx * .75) + 'px) translateY(' + (ry * .75) + 'px)';
    }


    init();
  }

  useEffect(() => {
    if (windowSize.innerWidth < 1100) return
    startHover()
  }, [])

  if (windowSize.innerWidth < 1100) return <img className="hover-img" src={cardImg} alt=""/>

  return (
    <div className="container">
      <div id="card" className="card">

        <img className="hover-img" src={cardImg} alt=""/>

        <div className="card__gloss"></div>

      </div>
    </div>
  )
}

export {
  HomeHoverCard
}
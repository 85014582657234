import React, {useEffect, useRef, useState} from 'react'
import c from './style.module.scss'
import backgroundImg from "../../../../assets/images/home-about-bg.jpg";
import {Wrap} from "../../../../components/wrappers/wrap";
import nftText from '../../../../assets/images/nft-collections-text.png'
import greenBg from '../../../../assets/images/green-bg.png'
import vectorBox from '../../../../assets/images/svg/vector-cube.svg'
import additionalBack from '../../../../assets/images/group-bg.png'
import additionalMobileBack from '../../../../assets/images/details-mobile.png'
import {AnimateHoc} from "../../../../components/animate-hoc";

const additionalArray = [
  {
    title: "Number of NFTs:",
    text: "10,000 Legion Universe NFTs and a collection of 100 Legion Ordinals"
  },
  {
    title: "Blockchains:",
    text: "Polygon and Bitcoin Ordinals!"
  },
  {
    title: "Price of Legion Universe NFTs:",
    text: "80 Polygon (MATIC)"
  },
  {
    title: "Price of Legion Posters Ordinals:",
    text: "0.2 Bitcoin (or FREE if you purchase 100 or more Legion Universe NFTs"
  },
  {
    title: "Marketplaces:",
    text: "Legion Universe NFTs will be on Opensea and Legion Ordinals are on Gamma.io"
  },

]

const HomeDetails = ({windowSize}) => {
  const [isInView, setIsInView] = useState(false)
  const ref = useRef()

  useEffect(() => {
    if (isInView) {
      window.removeEventListener("scroll", scrollListener)
    } else {
      window.addEventListener("scroll", scrollListener)
    }
  }, [isInView])

  const scrollListener = () => {
    if ((ref.current?.getBoundingClientRect().top - window.innerHeight) < -100) {
      setIsInView(true)
    }
  }

  return (
    <div
      style={{ backgroundImage: `url("${backgroundImg}")`}}
      className={c.wrap}
    >
      <div className={c.gradient} />
      <div className={c.content}>
        <Wrap>
          <div className={c.container}  ref={ref}>
            <div className={c.info}>
              <AnimateHoc isLeft={false} translateValue={200} start={0} isInView={isInView}>
                <img className={c.nftText} src={nftText} alt="nft-collection"/>
              </AnimateHoc>

              <AnimateHoc isLeft={false} translateValue={200} start={200} isInView={isInView}>
                <p className={c.infoText}>
                  We created this collection to reward our community and welcome the world to the Legion Universe.
                </p>
              </AnimateHoc>

              <AnimateHoc isLeft={false} translateValue={300} start={200} isInView={isInView}>
                <p className={c.infoText}>
                  We created this collection to reward our community and welcome the world to the Legion Universe.
                </p>
              </AnimateHoc>

              <AnimateHoc isLeft={false} translateValue={400} start={200} isInView={isInView}>
                <div className={c.blackSection}>
                  <div className={c.blackImg}>
                    <img className={c.greenBg} src={greenBg} alt=""/>
                    <img className={c.greenBg} src={vectorBox} alt=""/>
                  </div>

                  <p>
                    By purchasing a Legion Universe NFT you become a member of the worldwide Legion Universe Community and get VIP privileges.
                  </p>
                </div>
              </AnimateHoc>
            </div>

            <div className={c.additionalWrap}>
              {
                windowSize.innerWidth > 780 ? (
                  <div className={c.additional}>
                    <img className={c.additionalBack} src={additionalBack} alt="additional-back"/>

                    <div className={c.additionalContent}>
                      <h3 className={c.additionalTitle}>Details</h3>

                      <div className={c.additionalList}>
                        {
                          additionalArray.map(additionalItem => (
                            <div key={additionalItem.title} className={c.additionalItem}>
                              <span className={c.additionalSpanTitle}>{additionalItem.title}</span>
                              <span className={c.additionalSpanText}>{additionalItem.text}</span>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={c.additionalMobile}>
                    <img className={c.additionalMobileBack} src={additionalMobileBack} alt="additional-back"/>
                    <div className={c.additionalMobileContent}>
                      <h4 className={c.additionalMobileTitle}>Details</h4>

                      <div className={c.additionalMobileList}>
                        {
                          additionalArray.map(additionalItem => (
                            <div key={additionalItem.title} className={c.additionalMobileListItem}>
                              <span className={c.additionalSpanTitle}>{additionalItem.title}</span>
                              <span className={c.additionalSpanText}>{additionalItem.text}</span>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </Wrap>
      </div>
    </div>
  )
}


export {
  HomeDetails
}
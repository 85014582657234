import React from 'react'
import c from './style.module.scss'


const MenuBtn = ({toggleMenu, isMenuActive}) => {
  const menuClass = isMenuActive ? `${c.wrapActive} ${c.wrap}` : c.wrap

  return (
    <div className={menuClass} onClick={toggleMenu}>
      <span />
      <span />
      <span />
    </div>
  )
}


export {
  MenuBtn
}
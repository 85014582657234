import React, {useEffect, useRef, useState} from 'react'
import c from './style.module.scss'
import backgroundImg from "../../../../assets/images/universe-bg.png";
import roadmapText from "../../../../assets/images/roadmap-text.png";
import coolIcon from "../../../../assets/images/svg/vector-cool.svg";
import circleIcon from "../../../../assets/images/circle-icon.png";
import {Wrap} from "../../../../components/wrappers/wrap";
import {AnimateHoc} from "../../../../components/animate-hoc";

const roadmapData = [
  {
    label: "2015-2023",
    text: "Development of feature films and screenplays for LEGION UNIVERSE",
  },
  {
    label: "2023",
    text: "Launch NFT project to build Worldwide Legion Community",
  },
  {
    label: "",
    text: "Start PHASE I of LEGION UNIVERSE",
  },
  {
    label: "",
    text: "Create Legion Trading Card Game",
  },
  {
    label: "",
    text: "Create initial Comic Books",
  },
  {
    label: "",
    text: "Start LEGION EPIC and LEGION CHAMPIONS video games",
  },
  {
    label: "2024",
    text: "Launch LEGION EPIC and LEGION CHAMPIONS video games",
  },
  {
    label: "",
    text: "Release SPEED OF LIGHT and POWERS OF THE PAST",
  },
  {
    label: "",
    text: "Start production of LEGION, QUENTIN HOLLOW and DISCOVERY",
  },
  {
    label: "2024",
    text: "Release LEGION, QUENTIN and DISCOVERY",
  },
  {
    label: "",
    text: "Start PHASE II of LEGION UNIVERSE",
  }
]

const MapItem = ({item}) => {
  return (
    <div>
      <div className={c.item}>
        <div className={`${c.itemTitle} ${!!item.label ? c.topTitle : ''}`}>{item.label}</div>

        <div className={c.itemActions}>
          <div>
            {
              !!item.label ? (
                <img className={c.coolIcon} src={coolIcon} alt=""/>
              ) : (
                <span className={c.simpleStar}>
                  <img src={circleIcon} alt=""/>
                </span>
              )
            }
          </div>
          <div className={c.itemText}>{item.text}</div>
        </div>
      </div>
    </div>
  )
}

const HomeRoadmap = () => {
  const [isInView, setIsInView] = useState(false)
  const ref = useRef()

  useEffect(() => {
    if (isInView) {
      window.removeEventListener("scroll", scrollListener)
    } else {
      window.addEventListener("scroll", scrollListener)
    }
  }, [isInView])

  const scrollListener = () => {
    if ((ref.current?.getBoundingClientRect().top - window.innerHeight) < -100) {
      setIsInView(true)
    }
  }

  return (
    <div
      style={{backgroundImage: `url("${backgroundImg}")`}}
      className={c.wrap}
    >
      <div className={c.gradient} ref={ref}>
      </div>

      <div className={c.sectionContent}>
        <Wrap>
          <div className={c.container}>
            <AnimateHoc isInView={isInView} start={100} isLeft={true} translateValue={-300}>
              <div>
                <img className={c.roadmapText} src={roadmapText} alt="roadmap"/>
              </div>
            </AnimateHoc>

            <div>
              <div className={c.list}>
                {
                  roadmapData.map((item, index) => (
                    <AnimateHoc
                      key={Math.random()}
                      isInView={isInView}
                      start={(index + 1) * 200}
                      isLeft={false}
                      translateValue={100}
                    >
                      <MapItem item={item} />
                    </AnimateHoc>
                  ))
                }
              </div>
            </div>
          </div>
        </Wrap>
      </div>
    </div>
  )
}

export {
  HomeRoadmap
}
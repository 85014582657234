import React from 'react'
import c from './style.module.scss'
import {ButtonDefault} from "../buttons/button-default";
import usaIcon from "../../assets/images/countries/button_USA.svg";
import thailandIcon from "../../assets/images/countries/button_Thailand.svg";
import vietnamIcon from "../../assets/images/countries/button_Vietnam.svg";
import chinaIcon from "../../assets/images/countries/button_China.svg";
import japanIcon from "../../assets/images/countries/button_Japan.svg";
import koreaIcon from "../../assets/images/countries/button_Korea.svg";
import rIcon from "../../assets/images/countries/button_r.svg";

const countries = [
  {label: "usa", icon: usaIcon},
  {label: "thailand", icon: thailandIcon},
  {label: "vietnam", icon: vietnamIcon},
  {label: "china", icon: chinaIcon},
  {label: "japan", icon: japanIcon},
  {label: "korea", icon: koreaIcon},
  {label: "r", icon: rIcon},
]

const MobileMenu = ({isActive}) => {


  return (
    <div className={c.wrap} style={{transform: `translateX(${isActive ? "0" : "-100%"})`}}>
      <h2 className={c.title}>Menu</h2>
      <div className={c.btnWrap}>
        <ButtonDefault text="Connect Wallet" />
      </div>
      <h2 className={c.subTitle}>Choose a language</h2>
      <ul className={c.list}>
        {countries.map(item => (
          <li key={item.label}><img key={item.label} src={item.icon} size={40} alt=""/></li>
        ))}
      </ul>
      <ul className={c.menu}>
        <li>
          <a href="http://mint.legionuniverse.com/">
            RoadMap
          </a>
        </li>
        <li>
          <a href="http://mint.legionuniverse.com/">
            About
          </a>
        </li>
      </ul>
    </div>
  )
}


export {
  MobileMenu
}
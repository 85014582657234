import React from 'react'
import c from './style.module.scss'
import img from '../../../assets/images/svg/header-btn.svg'


const ButtonSmall = ({text}) => {

  return (
    <a href="http://mint.legionuniverse.com/">
      <div className={c.wrap}>
        <div className={c.dec} />
        <img className={c.img} src={img} alt=""/>
        <button className={c.btn}>
          {text}
        </button>
      </div>
    </a>
  )
}

export {
  ButtonSmall
}
import React from 'react'
import {Routes, Route, Navigate} from "react-router-dom";
import {APPRoutes} from "./const/app-routes";
import {HomePage} from "./pages/home";
import {TermsPage} from "./pages/terms";
import {PrivacyPage} from "./pages/privacy";
import {LegalPage} from "./pages/legal";
import ScrollToTop from "./components/scroll-top";

const useRoutes = () => {

  return (
    <div>
      <ScrollToTop />

      <Routes>

          <Route exact path={APPRoutes.home} element={<HomePage />} />
          <Route exact path={APPRoutes.terms} element={<TermsPage />} />
          <Route exact path={APPRoutes.privacy} element={<PrivacyPage />} />
          <Route exact path={APPRoutes.legal} element={<LegalPage />} />

          <Route
            path="*"
            element={<Navigate to={APPRoutes.home} />}
          />
      </Routes>
    </div>
  )
}

export {useRoutes}

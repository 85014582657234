import React, {useEffect, useRef, useState} from 'react'
import c from './style.module.scss'
import bottomDec from "../../../../assets/images/mobile-green-bottom.png";
import bgImage from "../../../../assets/images/mobile-categories-bg.png";


const HomeCategoriesMobileItem = ({item, isReverse}) => {
  const [isInView, setIsInView] = useState(false)
  const ref = useRef()

  useEffect(() => {
    if (isInView) {
      window.removeEventListener("scroll", scrollListener)
    } else {
      window.addEventListener("scroll", scrollListener)
    }
  }, [isInView])

  const scrollListener = () => {
    if ((ref.current?.getBoundingClientRect().top - window.innerHeight) < -100) {
      setIsInView(true)
    }
  }

  return (
    <div className={c.itemSection} ref={ref}>
      <div className={c.itemBlock}>
          <div key={item.title} className={c.itemWrap}>
            <img src={bottomDec} alt="dec-bottom" className={c.dec} />
            <img className={c.bgImage} src={bgImage} alt=""/>
            <div className={c.content}>
              <img src={item.image} alt=""/>
              <h4 className={c.title}>{item.title}</h4>
              <p className={c.text}>{item.text}</p>
            </div>
          </div>
      </div>
    </div>
  )
}


export {
  HomeCategoriesMobileItem
}
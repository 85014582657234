import React from 'react'
import c from './style.module.scss'
import {mockCategoriesList} from "../home-categories-list";
import {HomeCategoriesMobileItem} from "../home-categories-mobile-item";

const HomeCategoriesMobile = () => {

  return (
    <div className={c.wrap}>
      {
        mockCategoriesList.map((item, index) => (
          <HomeCategoriesMobileItem isReverse={index % 2 === 1} key={item.title} item={item} />
        ))
      }
    </div>
  )
}


export {
  HomeCategoriesMobile
}
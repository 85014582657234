import React, {useEffect, useRef, useState} from 'react'
import c from './style.module.scss'
import backgroundImg from "../../../../assets/images/mint-bg.png";
import coolIcon from "../../../../assets/images/svg/vector-cool.svg";
import mintText from "../../../../assets/images/how-to-mint-text.png";
import {Wrap} from "../../../../components/wrappers/wrap";
import {ButtonDefault} from "../../../../components/buttons/button-default";
import {AnimateHoc} from "../../../../components/animate-hoc";


const mintData = [
  {
    title: "We have made this part very easy whether you have a crypto wallet or not. If you are new to this space, simply click \"Mint\" and use a credit card.",
    terms: [],
    isHaveButton: true
  },
  {
    title: "If you are familiar with crypto wallets and have Polygon (MATIC) in your wallet, simply click “Mint” to go to our minting page:",
    terms: [
      "Press “Connect” to connect your wallet (MetaMask or Wallet Connect)",
      "Choose the number of NFTs you would like to purchase",
      "Click “Mint”",
      "Confirm in your Wallet"
    ]
  },
  {
    title: "If you want to purchase with a credit card, click “Mint” to go to our minting page:",
    terms: [
      "Choose the number of NFTs you would like to purchase with a credit card",
      "Click “Buy with credit card”",
      "Connect with Crossmint and follow the instructions",
      "Purchase your NFT with your credit card"
    ]
  }
]

const HomeMint = () => {
  const [isInView, setIsInView] = useState(false)
  const ref = useRef()

  useEffect(() => {
    if (isInView) {
      window.removeEventListener("scroll", scrollListener)
    } else {
      window.addEventListener("scroll", scrollListener)
    }
  }, [isInView])

  const scrollListener = () => {
    if ((ref.current?.getBoundingClientRect().top - window.innerHeight) < -100) {
      setIsInView(true)
    }
  }

  return (
    <div className={c.wrap}>
      <div
        className={c.imgBackground}
        style={{backgroundImage: `url("${backgroundImg}")`}}
      >
        <div className={c.gradient}>
          <div className={c.content} ref={ref}>
            <Wrap>
              <div>
                <AnimateHoc isInView={isInView} start={100} isLeft={true} translateValue={-300}>
                  <img className={c.mintText} src={mintText} alt=""/>
                </AnimateHoc>
              </div>

              <div className={c.list}>
                {
                  mintData.map((item, index) => (
                    <AnimateHoc key={item.title} isInView={isInView} start={(index + 1) * 200} isLeft={true} translateValue={300}>
                      <div className={c.item}>
                        <div className={c.count}>0{index+1}</div>
                        <p className={c.itemDescription}>
                          {item.title}
                        </p>

                        <div className={c.itemTerms}>
                          {
                            item.terms.map(termItem => (
                              <div className={c.termItem} key={Math.random()}>
                                <div>
                                  <img className={c.coolIcon} src={coolIcon} alt=""/>
                                </div>

                                <div>
                                  <p className={c.termText}>{termItem}</p>
                                </div>
                              </div>
                            ))
                          }
                        </div>

                        {
                          item.isHaveButton && (
                            <div className={c.btn}>
                              <ButtonDefault text="Mint Now" isIconShow={true} />
                            </div>
                          )
                        }
                      </div>
                    </AnimateHoc>
                  ))
                }
              </div>

              <div className={c.mobileBtn}>
                <ButtonDefault text="Mint Now" isIconShow={true} />
              </div>
            </Wrap>
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  HomeMint
}
import React, {useCallback, useRef, useState} from 'react'
import c from './style.module.scss'
import awardImg from '../../../../assets/images/award.png'
import spiderManImg from '../../../../assets/images/spiderman.png'
import avatarImg from '../../../../assets/images/avatar.png'
import grimImg from '../../../../assets/images/grim.png'
import flowerImg from '../../../../assets/images/flower.png'
import sliderBg from '../../../../assets/images/slider-bg.png'
import sliderBlackBg from '../../../../assets/images/mobile-black-bg.png'
import arrowRight from '../../../../assets/images/svg/arrow.svg'

const sliders = [
  {
    title: "Experience matters",
    text: "An Academy Award winner and award winning film professionals who have worked on some of the highest grossing films of all time",
    img: flowerImg,
    type: "unique"
  },
  {
    title: "Award Winners",
    text: "Award Winning Management Team.",
    img: awardImg
  },
  {
    title: "Game Experience",
    text: "Spiderman, G.I. Joe, Tenchu and more.",
    img: spiderManImg
  },
  {
    title: "Film Experience",
    text: "Avatar, Avengers, Superman, X-Men and more.",
    img: avatarImg
  },
  {
    title: "Television",
    text: "Grimm, Trauma, Pushing Daisies, iZombie and more",
    img: grimImg
  }
]

const HomeSliderMobile = () => {
  const [activeSliderNumber, setActiveSliderNumber] = useState(0)
  const [sliderLeft, setSliderLeft] = useState(0)
  const containerRef = useRef()
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setSliderLeft(node.getBoundingClientRect().left);
    }
  }, []);

  const changeSliderValue = (type) => {
    let newValue
    if (type === 'add') {
      if (activeSliderNumber + 1 > sliders.length - 1) return
      newValue = activeSliderNumber + 1

      let count = 1;
      const slideTimer = setInterval(function(){
        if(count === 11) {
          window.clearInterval(slideTimer);
          return
        }
        containerRef.current.scrollLeft = activeSliderNumber * 300 + (count * 30)
        count++;
      }, 20);

    }
    else {
      if (activeSliderNumber - 1 < 0) return
      newValue = activeSliderNumber - 1

      let count = 1;
      const slideTimer = setInterval(function(){
        if(count === 11) {
          window.clearInterval(slideTimer);
          return
        }
        containerRef.current.scrollLeft = activeSliderNumber * 300 - (count * 30)
        count++;
      }, 20);
    }

    setActiveSliderNumber(newValue)

  }


  return (
      <div className={c.wrap}>
        <div ref={measuredRef} className={c.headerWrap}>
          <div className={c.header}>
              <div
                className={c.line}
              >
                <div
                  className={c.lineActive}
                  style={{
                    width: `${100 / sliders.length}%`,
                    marginLeft: `${100 / sliders.length * activeSliderNumber}%`
                  }}
                />
            </div>

            <div className={c.buttons}>
              <img
                className={c.arrowLeft}
                src={arrowRight}
                alt="arrow-left"
                onClick={() => changeSliderValue("")}
              />
              <img
                className={c.arrowRight}
                src={arrowRight}
                onClick={() => changeSliderValue("add")}
                alt="arrow-right"
              />
            </div>
          </div>
        </div>

        <div className={c.container} style={{paddingLeft: `${sliderLeft + 20}px`}} ref={containerRef}>
          <div className={c.list}>
            {
              sliders.map(item => (
                <div key={item.title} className={c.item}>
                  <img className={c.sliderImg} src={item.type === 'unique' ? sliderBlackBg : sliderBg} alt=""/>
                  <div className={c.itemWrap}>
                    <div className={c.itemContainer}>
                       <img className={c.imageBg} src={item.img} alt=""/>

                      <div className={c.itemContent}>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
  )
}


export {
  HomeSliderMobile
}
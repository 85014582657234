import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useRoutes} from "./routes";

const App = () => {
  const routes = useRoutes()

  return (
    <div className="app">
      <Router>
        {routes}
      </Router>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
      />
    </div>
  );
}

export default App;

import React from 'react'
import c from './style.module.scss'
import {Wrap} from "../../../../components/wrappers/wrap";
import logo from '../../../../assets/images/logo.png'
import {useNavigate} from "react-router-dom";
import {APPRoutes} from "../../../../const/app-routes";

const HomeFooter = () => {
  const navigate = useNavigate()

  return (
    <Wrap>
      <div className={c.wrap}>
        <div>
          <img onClick={() => navigate(APPRoutes.home)} src={logo} alt=""/>
        </div>

        <ul className={c.list}>
          <li onClick={() => navigate(APPRoutes.terms)}>Terms & Conditions</li>
          <li onClick={() => navigate(APPRoutes.privacy)}>Privacy Policy</li>
          <li onClick={() => navigate(APPRoutes.legal)}>Legal</li>
        </ul>
      </div>
    </Wrap>
  )
}

export {
  HomeFooter
}
import React from 'react'
import c from './style.module.scss'
import bg from "../../../assets/images/home-offer-bg.png";
import mobBG from "../../../assets/images/mobile-offer-bg.png";
import {ButtonDefault} from "../../buttons/button-default";
import {ButtonSubscribe} from "../../buttons/button-subscribe";


const OfferWrap = ({text, windowSize, price}) => {

  return (
    <div className={c.wrap}>
      <img className={c.background} src={windowSize.innerWidth > 690 ? bg : mobBG} alt=""/>

      <div className={c.content}>
        <p className={c.text}>
          {text}
          <span className={c.grey}> [${price} USD]</span>
        </p>

        <div className={c.buttons}>
          <ButtonDefault text="Mint Now" isIconShow={true} />
          <ButtonSubscribe text="an offer that won't come knockingagain!" />
        </div>
      </div>
    </div>
  )
}

export {
  OfferWrap
}
import React, {useEffect, useState} from 'react'


const OpacityHoc = ({children, start, isInView}) => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (!isInView) return
    setTimeout(() => {
      setIsActive(true)
    }, start)
  }, [isInView])

  return (
    <div style={{transition: "1s", opacity: isActive ? 1 : 0}}>
      {children}
    </div>
  )
}

export {
  OpacityHoc
}
import React, {useEffect, useState} from 'react'
import c from './style.module.scss'


const AnimateHoc = ({children, start, translateValue, isInView, isLeft}) => {
  const [isMount, setIsMount] = useState(false)

  useEffect(() => {
    if (!isInView) return
    setTimeout(() => {
      setIsMount(true)
    }, start)
  }, [isInView])

  const transformValue = isLeft ? `translateX(${isMount ? 0 : translateValue}px)` : `translateY(${isMount ? 0 : translateValue}px)`

  return (
    <div
      className={c.wrap}
      style={{
        transform: transformValue,
        opacity: isMount ? 1 : 0,
      }}
    >
      {children}
    </div>
  )
}


export {
  AnimateHoc
}
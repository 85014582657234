import React, {useEffect, useRef, useState} from 'react'
import c from './style.module.scss'
import backgroundImg from "../../../../assets/images/home-about-bg.jpg";
import {Wrap} from "../../../../components/wrappers/wrap";
import posterImg from '../../../../assets/images/video-poster.jpg'
import {FaPlay} from "react-icons/fa";
import bonusImg from '../../../../assets/images/bonus-pack.png'
import bonusIcon1 from '../../../../assets/images/bonus_icon1.png'
import bonusIcon2 from '../../../../assets/images/bonus_icon2.png'
import bonusIcon3 from '../../../../assets/images/bonus_icon3.png'
import bonusIcon4 from '../../../../assets/images/bonus_icon4.png'
import {HomeBonusItem} from "../home-bounus-item";
import {AnimateHoc} from "../../../../components/animate-hoc";
import {VideoComponent} from "../../../../components/video-component";


const bonusList = [
  {
    title: "Legion 5 Pack",
    icon: bonusIcon1,
    terms: [
      "Receive the Premier Edition of the Legion Trading Card Game",
      "Be entered in a pool to win the opportunity to be an extra in one of the films"
    ]
  },
  {
    title: "Legion 25 Pack",
    icon: bonusIcon2,
    terms: [
      "All items from previous pack",
      "Receive 5 Legion Concept Posters (1 for each Franchise) signed by Lee Baker the screenwriter",
      "Receive 5 Legion T-shirts (1 for each Franchise)"
    ]
  },
  {
    title: "Legion 50 Pack",
    icon: bonusIcon3,
    terms: [
      "All from previous Packs",
      "Receive an opportunity for a CAMEO ROLE in one of the films",
      "Receive an invitation to attend a RED CARPET PREMIER SCREENING for one of the films"
    ]
  },
  {
    title: "Legion 100 Pack",
    icon: bonusIcon4,
    terms: [
      "All from previous Packs",
      "Receive a LEGION BITCOIN ORDINAL!",
      "Become a member of the LEGION ORDINALS ELITE INVESTMENT CLUB!"
    ]
  },
]

const HomeAbout = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [isInView, setIsInView] = useState(false)
  const ref = useRef()

  useEffect(() => {
    if (isInView) {
      window.removeEventListener("scroll", scrollListener)
    } else {
      window.addEventListener("scroll", scrollListener)
    }
  }, [isInView])

  const scrollListener = () => {
    if ((ref.current?.getBoundingClientRect().top - window.innerHeight) < -100) {
      setIsInView(true)
    }
  }

  const playClass = isInView ? `${c.play} ${c.playActive}` : c.play
  const posterClass = isInView ? `${c.poster} ${c.posterActive}` : c.poster


  return (
    <div
      className={c.wrap}
      style={{backgroundImage: `url("${backgroundImg}")`}}
    >
      <Wrap>

        <div ref={ref} className={c.posterWrap}>
          {!!isVideoOpen && (
            <VideoComponent closeHandler={() => setIsVideoOpen(false)} />
          )}
          <img className={posterClass} src={posterImg} alt="video-poster"/>

          <div className={playClass} onClick={() => setIsVideoOpen(!isVideoOpen)}>
            <FaPlay  />
          </div>
        </div>

        <div className={c.information}>
          <div>
            <AnimateHoc isInView={isInView} start={300} isLeft={true} translateValue={-200}>
              <img className={c.bonusImg} src={bonusImg} alt=""/>
            </AnimateHoc>
          </div>

          <div>
            <AnimateHoc isInView={isInView} start={300} isLeft={true} translateValue={200}>
              <p className={c.infoText}>
                Qualify for the following Bonus Packs by purchasing the Bonus Pack number of Legion Universe Digital
                Trading Cards.
                A Bonus Pack gives you additional physical bonuses (items mailed to you) as well as potential
                opportunities to join with us in the physical production and premiers of the films.
              </p>
            </AnimateHoc>
          </div>
        </div>

        <div className={c.bonusWrap}>
          {
            bonusList.map(item => (
              <HomeBonusItem item={item} key={Math.random()}/>
            ))
          }
        </div>
      </Wrap>
    </div>
  )
}


export {
  HomeAbout
}
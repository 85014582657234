import React from 'react'
import {Navbar} from "../../components/navbar";
import c from './style.module.scss'
import {Wrap} from "../../components/wrappers/wrap";
import img from '../../assets/images/legion-big.png'
import {HomeFooter} from "../home/components/home-footer";


const basicTerms = [
  `Using this website implies your acceptance of these conditions. 
  If you do not fully accept them, your entry to this site will be considered unauthorized and you will have to stop using it immediately.`,
  `You must be 13 years old or older to use our site or apps.`,
  `You are responsible for any activity that occurs under your screen name.`,
  `You are responsible for keeping your account secure.`,
  `You must not abuse, harass, threaten or intimidate other Legion Universe users.`,
  `You are solely responsible for your conduct and any data, text, information, screen names, graphics, photos, profiles, audio and video clips, links (“Content”) that you submit, post, and display on the Legion Universe service.`,
  `You must not modify, adapt or hack Legion Universe or modify another website so as to falsely imply that it is associated with Legion Universe`,
  `You must not create or submit unwanted email to any Legion Universe members (“Spam”).`,
  `You must not transmit any worms or viruses or any code of a destructive nature which includes adding a URL or website address which leads to phishing or any other type of behavior which may lead to spamming or potential damage for any users of Legion Universe.`,
  `You must not, in the use of Legion Universe, violate any laws in your jurisdiction (including but not limited to copyright laws).`
]

const general = [
  `We reserve the right to modify or terminate the Legion Universe service for any reason, without notice at any time.`,
  `We reserve the right to alter these Terms of Use at any time. If the alterations constitute a material change to the Terms of Use, we will notify you via internet mail according to the preference expressed on your account. What constitutes a “material change” will be determined at our sole discretion, in good faith and using common sense and reasonable judgement.`,
  `We reserve the right to refuse service to anyone for any reason at any time.`,
  `We may, but have no obligation to, remove Content and accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Use.`
]

const copyRight = [
  `We claim no intellectual property rights over the material you provide to the Legion Universe service.`,
  `We encourage users to contribute their creations to the public domain or consider progressive licensing terms.`
]

const rules = [
  `Violence: We prohibit any threats of violence against an individual or any group of people. We also do not allow the support of or encouragement of violence.`,
  `Terrorism: We prohibit any and all forms of terrorism or expressions for or support of terrorism.`,
  `Child sexual exploitation: We prohibit any and all uses of child sexual exploitation and any behaviors which can lead to or support sex-trafficing.`,
  `Abuse/harassment: All forms of abuse and harassment are prohibited.`,
  `Hateful actions: We prohibit hate in all forms on any basis including but not limited to race, ethnicity, national origin, caste, sexual orientation, gender, gender identity, religious affiliation, age, disability, or serious disease.`,
  `Suicide or self-harm: We prohibit promotion or encouragment of suicide or self-harm to others or to yourself.`,
  `Inappropriate media: We do not allow excessively gory content or any media depicting sexual violence, sexual promiscuity or pornography which includes but is not limited to nude images and partially nude images which are described as photos with clothes removed and underwear exposed. We do not allow “Adult” or sexually explicit images, posts, pages or groups.`,
  `legal goods or services: We prohibit any unlawful purpose or performance or promotion of illegal activities. This includes selling, buying, or any form of transactions in illegal goods or services, including certain regulated goods or services.`,
  `Private information: We do not allow the posting of other people’s private information (home phone number and address) without their express written permission. We also prohibit threats to do so.`,
  `Nudity: You may not post or share intimate photos or videos of nudity or pornography.`,
  `Impersonation: Attempting to mislead or confuse others by impersonating individuals or groups is prohibited.`,
  `Copyright and trademark: You may not violate any person or organization’s intellectual property rights, including copyright and trademark.`,
  `Third-party advertising: We allow advertisers to advertise through our site. You may not upload or promote third-party advertisements without our prior, written authorization.`,
  `Enforcement: We will enforce violations of our guiding principles, terms, conditions and rules with deletion of content and any other such interactions. If violations continue it will result in permanent removal of your account from Legion Universe. Legion Universe may, at any time, update and enhance our terms, conditions and rules.`
]

const TermsPage = () => {

  return (
    <div>
      <Navbar />
      <Wrap>
        <div className={c.container}>
          <img src={img} alt="logo" className={c.img} />
          <h2 className={c.termsTitle}>TERMS</h2>

          <div className={c.content}>
            <h4>Legion Universe Terms Overview:</h4>
            <p>
              The Legion Universe revolves around superhero based film franchises and includes films,
              television shows, video games, interactive multimedia, an international community and much more.
              Using this website and apps, games, interactive multimedia or any other website associated with the Legion
              Universe implies your acceptance of these conditions. If you do not fully accept them, your entry to this site
              will be considered unauthorized and you will have to stop using it immediately.
            </p>

            <p className={c.basic}>Basic Terms:</p>

            <div className={c.basic}>
              {
                basicTerms.map((item, index) => (
                  <p key={index}>
                    {`${index + 1}. ${item}`}
                  </p>
                ))
              }
            </div>

            <p>
              Violation of any of these agreements will result in the termination of your Legion Universe account.
              While Legion Universe prohibits such conduct and content on its site, you understand and agree that Legion Universe cannot be responsible
              for the Content posted on its web site and you nonetheless may be
              to such materials and that you use the Legion Universe service at your own risk.
            </p>

            <p className={c.basic}>General Condition:</p>

            <div className={c.basic}>
              {
                general.map((item, index) => (
                  <p key={index}>
                    {`${index + 1}. ${item}`}
                  </p>
                ))
              }
            </div>

            <p className={c.basic}>Copyright (What’s Yours is Yours):</p>

            <div className={c.basic}>
              {
                copyRight.map((item, index) => (
                  <p key={index}>
                    {`${index + 1}. ${item}`}
                  </p>
                ))
              }
            </div>

            <p className={c.basic}>Rules:</p>

            <div className={c.basic}>
              {
                rules.map((item, index) => (
                  <p key={index}>
                    {`${index + 1}. ${item}`}
                  </p>
                ))
              }
            </div>


            <div className={c.date}>Last updated on: February 11, 2023</div>
          </div>
        </div>
      </Wrap>

      <HomeFooter />
    </div>
  )
}

export {
  TermsPage
}
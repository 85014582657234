import React from 'react'
import c from './style.module.scss'
import {Wrap} from "../../../../components/wrappers/wrap";
import person1 from '../../../../assets/images/persons/person.jpg'
import person2 from '../../../../assets/images/persons/person2.jpg'
import person3 from '../../../../assets/images/persons/person3.jpg'
import person4 from '../../../../assets/images/persons/person4.jpg'
import person5 from '../../../../assets/images/persons/person5.jpg'
import coolSvg from '../../../../assets/images/svg/vector-cool.svg'
import teamBg from '../../../../assets/images/team-bg.png'
import teamWhiteDec from '../../../../assets/images/team-white-dec.png'
import teamText from '../../../../assets/images/team-text.png'
import listItemBg from '../../../../assets/images/team-list-item-bg.png'
import listItemDec from '../../../../assets/images/team-green-table-dec.png'
import {FaTwitter} from "react-icons/fa";


const teamData = [
  {
    isRevers: false,
    title: "Lee Baker",
    position: "President/CEO",
    avatar: person5,
    terms: [
      "Former owner/president of Sandman Studios.",
      "Film & TV Credits include Race to Witch Mountain, Pushing Daisies, Grimm, Trauma, The Aquabats, Raising Hope, A Gifted Man, The Closer, iZombie.",
      "Video game credits: Advent Rising, G.I. Joe, Monopoly, Stuart Little, & Pirates of the Caribbean.",
      "Team recipient of 10 Telly Awards, 2 Davey Awards, 2 W3 Awards, 15 Horizon Awards, 5 Communicator Awards and 6 Interactive Media Awards.",
    ],
    imb: "https://www.imdb.com/name/nm2899214/",
    twitter: "https://twitter.com/leesamuelbaker"
  },
  {
    isRevers: false,
    title: "Jarom Sidwell",
    position: "Head of Production",
    avatar: person4,
    terms: [
      "Career credits include work on Avatar, Man of Steel, Avengers, The Adventures of TinTin, X-Men: First Class, A-Team, Gulliver’s Travels, Transformers, We Own the Night, The Hitcher, Texas Chainsaw Massacre—The Beginning and Zoom.",
      "Produced VFX commercials at Radium/Reel FX.",
      "Currently leading Advent FX teams and perfecting use of Virtual and Augmented Reality.",
    ],
    imb: "https://www.imdb.com/name/nm2688562/",
    twitter: "https://twitter.com/jaromSidwell"
  },
  {
    isRevers: true,
    title: "Cameron Curriden",
    position: "Chief Financial Officer",
    avatar: person3,
    terms: [
      "In 2020, Cameron was nominated for the Top 100 people in Finance and in 2021 he was listed as the top 40 under 40 professionals in the US.",
      "He built a real estate company from scratch to multimillion dollar valuation in 18 months.",
      "Actively involved in over $6.5 Billion in transactions.",
      "Founded and exited 3 companies and also has invested in and has sat on the advisory boards for multiple startup companies in the tech, entertainment and digital securities space.",
    ]
  },
  {
    isRevers: true,
    title: "Keith Merrill",
    position: "President/CEO",
    avatar: person2,
    terms: [
      "Academy Award Winner: Keith won the Academy Award for his film The Greatest American Cowboy.",
      "Directed over 20 feature films including Windwalkers, Amazon, 12 Dogs of Christmas, Broken Hill, Alamo: the Price of Freedom and many more…",
      "Directed many television shows including The Wild West, Mr. Krueger’s Christmas, The Magical World of Disney and others…",
      "Published author and has written many novels.",
    ],
    imb: "https://www.imdb.com/name/nm0581303/",
  },
  {
    isRevers: true,
    title: "Augustine Kim",
    position: "Business Development/Distribution",
    avatar: person1,
    terms: [
      `
        Distribution of over 300 motion pictures and TV series including Once Upon A Time in America, Never Say Never Again,
         The Killing Fields, Night Game, Platoon, Witness, Endless Love, Nightmare on Elm Street, Beverly Hills Cop, Moon Walker, 
         Cotton Club, Apocalypse Now, LA Confidential, El Salvador, The Fugitive and The Empire of the Sun,
         Wild Wild World Animals, Samuel Goldwyn’s package, Hercules’s series, The Six Million Dollar Man, Combat and more.
      `
    ]
  }
]

const HomeTeam = () => {


  return (
    <Wrap>
      <div className={c.wrap}>
        <div>
          <div className={c.teamItemLogo}>
            <img className={c.teamWhiteDec} src={teamWhiteDec} alt=""/>
            <img className={c.teamPreview} src={teamBg} alt=""/>
            <img className={c.teamText} src={teamText} alt=""/>
          </div>
        </div>

        {
          teamData.map(person => (
            <div key={person.title}>
              <div className={c.item}>
                <img className={person.isRevers ? c.listItemBgReverse : c.listItemBg} src={listItemBg} alt=""/>
                <img className={person.isRevers ? c.listItemDecReverse : c.listItemDec} src={listItemDec} alt=""/>

                <div className={c.itemContainer}>

                  <div className={c.itemHeader}>
                    <div className={c.iconWrap}>
                      <img src={person.avatar} alt={person.title}/>
                    </div>

                    <div>
                      <div className={c.itemRightIcon}>
                        <a rel="noreferrer"  className={`${c.link} ${c.yellowIcon}`} href={person.imb} target={"_blank"}>
                           IMDb1
                        </a>

                        <a rel="noreferrer"  className={`${c.link} ${c.twitterIcon}`} href={person.twitter} target={"_blank"}>
                          <FaTwitter />
                        </a>
                      </div>

                      <h3 className={c.itemName}>{person.title}</h3>
                      <h5 className={c.itemPosition}>{person.position}</h5>
                    </div>
                  </div>

                  <div className={c.itemTerms}>
                    {
                      person.terms.map(termItem => (
                        <div className={c.termItem} key={Math.random()}>
                          <span>
                            <img src={coolSvg} alt=""/>
                          </span>

                          <span className={c.termText}>{termItem}</span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>

          ))
        }
      </div>
    </Wrap>
  )
}



export {
  HomeTeam
}
import React, {useEffect, useState} from 'react'
import c from './style.module.scss'
import logo from '../../assets/images/logo.png'
import {ButtonSmall} from "../buttons/button-small";
import {CountrySelect} from "../selects/country-select";
import {MobileMenu} from "../mobile-menu";
import {MenuBtn} from "./components/menu-btn";
import {APPRoutes} from "../../const/app-routes";
import {useNavigate} from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate()
  const [isMenuActive, setIsMenuActive] = useState(false)
  const [isInTop, setIsTop] = useState(true)
  const [isMount, setIsMount] = useState(false)
  const toggleMenu = () => setIsMenuActive(!isMenuActive)


  const wrapClass = isInTop ? c.wrap : `${c.wrap} ${c.wrapActive}`
  const handleScroll = () => {
    const position = window.pageYOffset;

    if (position > 10 && isInTop) {
      setIsTop(false);
    } else if (position < 10) {
      setIsTop(true)
    }
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    setIsMount(true)

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${wrapClass} ${isMount ? c.wrapRendered : ''}`}>
      <MobileMenu isActive={isMenuActive} />
      <div className={c.section}>
        <div className={c.container}>
          <div className={c.logoWrap}>
            <img onClick={() => navigate(APPRoutes.home)} className={c.logo} src={logo} alt="legion-logo"/>
          </div>

          <div className={c.contentWrap}>
            <ul className={c.menu}>
              <li>
                <span className={c.listDec} />
                <a href="http://mint.legionuniverse.com/">
                  RoadMap
                </a>
              </li>
              <li>
                <span className={c.listDec} />
                <a href="http://mint.legionuniverse.com/">
                  About
                </a>
              </li>
            </ul>

            <div className={c.actions}>
              <CountrySelect />
              <ButtonSmall text="Connect Wallet" />
            </div>

            <MenuBtn isMenuActive={isMenuActive} toggleMenu={toggleMenu} />
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  Navbar
}